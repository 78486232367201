import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    Modal,
    FormControl
} from 'react-bootstrap';

import { unlockVehicleDoor } from '../../apiCalls';


function UnlockModal({
    activeShift: {
        vehicleRegistration,
        workplace,
        start
    },
    profileName,

    onClose = () => { }
}) {

    let [error, setError] = useState('');
    let [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, setValue } = useForm()


    useEffect(() => {
        if (!register)
            return

        register('pin');
    }, [])

    function handleKeyPress(target) {
        if (target.charCode !== 13)
            return

        submit();
    }

    function unlock({ pin }) {

        if (!pin)
            return

        setError('');
        setIsLoading(true);

        return unlockVehicleDoor(vehicleRegistration, profileName, pin, workplace, start)
            .then(() => {
                onClose();
                alert('The car is unlocked!')
            })
            .catch(err => {
                console.error('Error when unlocking vehicle', err);
                setError('Could not unlock car. Either incorrect PIN code or network error. Try again!');

                return alert(`Could not unlock car, please try again. ${err}`);
            })
            .finally(() => setIsLoading(false))

    }

    function submit() {
        handleSubmit(unlock)()
    }

    return (
        <Modal bsSize='small' show={true} onHide={onClose} >
            <Modal.Header >
                <Modal.Title> Enter PIN code </Modal.Title>
            </Modal.Header >

            <Modal.Body  >
                <FormControl disabled={isLoading}
                    componentClass='input'
                    type='number'
                    pattern='[0-9]*'
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setValue('pin', e.target.value)}
                    placeholder='pin code' />

            </Modal.Body>

            <Modal.Footer>
                <Button disabled={isLoading} onClick={onClose}> Cancel </Button>
                <Button bsStyle='success' disabled={isLoading}
                    bsSize='large'
                    onClick={submit} >Unlock</Button> <br />
                <p> {error} </p>
            </Modal.Footer>

        </Modal>
    )
}

export default UnlockModal;