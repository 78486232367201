import React, { Component, useEffect } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Stockholm');

function Start(props) {
  const { isAuthenticated } = props.auth;

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production' &&
      window.location.protocol !== 'https:') {

      window.location =
        'https://' +
        window.location.hostname +
        window.location.pathname +
        window.location.hash;

    }
  }, [])


  function goTo(route) {
    props.history.replace(`/${route}`);
  }

  function login() {
    props.auth.login();
  }

  function logout() {
    props.auth.logout();
  }


  return (
    <div>
      {!isAuthenticated() && (
        <div>
          <Navbar fluid>
            <Navbar.Header>
              <Navbar.Brand>
              </Navbar.Brand>

              <Button
                bsStyle='primary'
                className='btn-margin navbar-btn pull-right'
                onClick={login}
              >
                Login
              </Button>
            </Navbar.Header>
          </Navbar>
          <div className='first'>
          </div>
        </div>
      )}
      {isAuthenticated() && (
        <Navbar fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <a href='/home'>
                {/* <img src='gordon_logo.png' alt='Gordon Delivery' /> */}
              </a>
            </Navbar.Brand>

            <Button
              bsStyle='primary'
              className='btn-margin navbar-btn pull-right'
              onClick={logout}
            >
              Log out
            </Button>
          </Navbar.Header>
        </Navbar>
      )}
    </div>
  )
}

export default Start;
