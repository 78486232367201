import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister, registerServiceWorker } from './registerServiceWorker';
import dotenv from 'dotenv';


import './lib/font-awesome-4.7.0/css/font-awesome.min.css';
import './lib/bootstrap-3.4.1-dist/css/bootstrap.min.css';
dotenv.config();

ReactDOM.render(<App />, document.getElementById('root'));

//registerServiceWorker();
unregister();