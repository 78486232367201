import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import 'moment/locale/sv';
import {
  Button,
  ButtonToolbar,
  Panel
} from 'react-bootstrap';
moment.tz.setDefault('Europe/Stockholm');

// MODALS
import ErrorModal from '../components/modals/ErrorModal';
import TimezynkModal from '../components/modals/TimezynkModal';
import LockModal from '../components/modals/LockModal';
import UnlockModal from '../components/modals/UnlockModal';
//


import { getShifts, getPin } from '../apiCalls';

function Home(props) {

  let [activeShift, setActiveShift] = useState();
  let [shiftList, setShiftList] = useState([]);

  let [activeModal, setActiveModal] = useState('');

  let [profile, setProfile] = useState();
  let [nopass, setnopass] = useState();



  const { isAuthenticated, getProfile } = props.auth;

  useEffect(async () => {
    moment.locale('sv');



    if (!isAuthenticated())
      return console.log('not authenticated');


    let profile = await getProfile();

    setProfile(profile);

    let shiftsData;

    try {
      shiftsData = await getShifts(profile.name);
    } catch (err) {
      //this.setState({ showTimeZynkModal: true });
      console.error('error when fetching tz data', err);
      return
    }

    if (shiftsData && shiftsData.url)
      setresulturl(shiftsData.url);

    let shifts = shiftsData;

    if (!shifts || shifts.length < 1)
      return setnopass(true);

    for (const shift of shifts) {

      let startTime = moment.tz(shift.start,'Europe/Stockholm');
      let endTime = moment.tz(shift.end, 'Europe/Stockholm');

      let today = new Date();
      let newStart = moment(startTime).add(-70, 'm').toDate();
      let newEnd = moment(endTime).add(720, 'm').toDate();


      shift.workplace = shift.arbetsplats;
      shift.vehicleRegistration = shift.fordon;


      shift.disabled = true;

      if (today.getTime() > newStart.getTime()) {
        shift.disabled = false;
      }
      else {
        shift.msg = 'It is not possible to unlock / lock your vehicle because it is too long until your route starts.';
      }

      if (shift.fordon === 'Own fleet/Rental car/No car') {
        shift.disabled = true;
        shift.msg = "It is not possible to unlock / lock vehicles of type 'Own fleet/Rental car/No car'";
      }

      shift.title = `${shift.arbetsplats} ${startTime.format('YYYY-MM-DD')} ${startTime.format('LT')} - ${endTime.format('LT')}`;
    }

    return setShiftList(shifts);

  }, [])


  const closeModal = () => setActiveModal('');

  function getActiveModal() {

    switch (activeModal) {
      // case 'timezynk':
      //   return <TimezynkModal onClose={closeModal} activeShift={activeShift} />
      case 'unlock':
        return <UnlockModal onClose={closeModal} activeShift={activeShift} profileName={profile.name} />

      case 'lock':
        return <LockModal onClose={closeModal} activeShift={activeShift} profileName={profile.name} />

      case 'error':
        return <ErrorModal onClose={closeModal} activeShift={activeShift} profileName={profile.name} />
    }
  }


  function showOpenModal(shift) {

    getPin(shift.vehicleRegistration, profile.name);
    setActiveShift(shift);
    setActiveModal('unlock');
  }


  function showCloseModal(shift) {

    let today = new Date();
    let newEnd = moment(shift.end).add(720, 'm').toDate();

    if (today.getTime() > newEnd.getTime()) {
      return alert('It is not possible to lock your vehicle right now because your route is not active. Reload the page.');
    }

    setActiveShift(shift)
    setActiveModal('lock');
  }


  if (!isAuthenticated()) {
    return <div className='container' ></div>
  }

  return (
    <div className='container' >
      <div >
        <p>Hey! Here are your routes for today. Good luck!</p>

        {activeModal && getActiveModal()}

        <Panel header='Your routes'
          bsStyle='default'
          bsSize='large'
        >
          {nopass && (<p> You have no routes scheduled for today </p>)}

          {!nopass && (

            <ul className='list-group text-center pass' >
              {
                shiftList.map(function (shift, index) {
                  return (
                    <li key={index}>
                      <Panel header={shift.title}
                        bsStyle='primary'
                        bsSize='large' >

                        <p>
                          <i className='fa fa-truck fa-2x' ></i>
                          <b> {shift.vehicleRegistration} </b>
                        </p >

                        <ButtonToolbar >
                          <Button
                            bsStyle='success'
                            bsSize='large'
                            width='100px'
                            className='btn-margin navbar-btn btn-gordoneer'
                            onClick={() => showOpenModal(shift)}
                            disabled={shift.disabled} >

                            <i className='fa fa-unlock' ></i> UNLOCK
                          </Button>

                          <Button
                            bsStyle='success'
                            bsSize='large'
                            width='100px'
                            disabled={shift.disabled}
                            className='btn-margin navbar-btn btn-gordoneer'
                            onClick={() => showCloseModal(shift)} >
                            <i className='fa fa-lock' ></i> LOCK
                          </Button>
                        </ButtonToolbar >

                        <p> {shift.msg} </p>

                      </Panel >

                    </li>)
                })
              }
            </ul>)}
        </Panel>

        <br />
        <p>Do you have any questions or problems? Call Gordon Success Tower <a href='tel:+46105518093'>+46 010 551 80 93</a></p>
        <br />
        {/* <p>Klicka här för <a href='https://goo.gl/vtkhhc' target='_blank' rel='noopener noreferrer'>Instruktioner</a></p> */}

      </div>
    </div>
  );
}


export default Home;