import React from 'react';
import { Route, Router } from 'react-router-dom';
import Start from './routes/start';
import Home from './routes/home';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';
import './App.css';



const auth = new Auth();

function App() {

  const handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      auth.handleAuthentication();
    }
  }
  

  return (
    <Router history={history} component={Start}>
      <div>
        <Route path='/' render={(props) => <Start auth={auth} {...props} />} />
        <Route path='/home' render={(props) => <Home auth={auth} {...props} />} />
        <Route path='/callback' render={(props) => {
          handleAuthentication(props);
          return <Callback {...props} />
        }} />
      </div>
    </Router>
  );
}

export default App;