import React, { useState, userRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    Modal,
    FormControl
} from 'react-bootstrap';


import { lockVehicleDoor } from '../../apiCalls';

function LockModal({
    activeShift: {
        vehicleRegistration,
        workplace
    },
    profileName,


    onClose = () => { }
}) {

    let [error, setError] = useState('');
    let [isLoading, setIsLoading] = useState(false)

    const { register, handleSubmit, setValue } = useForm();


    useEffect(() => {
        if (!register)
            return

        register('comment');
    }, [])



    function lockCar(data) {
        let { comment } = data;
        setError('');
        setIsLoading(true);

        return lockVehicleDoor(vehicleRegistration, profileName, workplace, comment)
            .then(() => {
                onClose();
                alert('Lock command sent to the car, check that it is really locked!');
            })
            .catch(err => {
                console.error('error when locking vehicle', err);
                setError(err);
                alert('Could not lock the car. Try again!');
            })
            .finally(() => setIsLoading(false));
    }

    function submit() {
        handleSubmit(lockCar)()
    }


    return (
        <Modal show={true} onHide={onClose} >
            <Modal.Header>
                <Modal.Title> Go through the checklist before locking the car: </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className='checklistUl' disabled={isLoading}>
                    <li><input type='checkbox' aria-label='...' {...register('stadat')} /> Neat and tidy</li>
                    <li><input type='checkbox' aria-label='...' {...register('lasskap')} /> Lock the cabinet in the back</li>
                    <li><input type='checkbox' aria-label='...' {...register('spannband')} /> Straps/spännband are available</li>
                    <li><input type='checkbox' aria-label='...' {...register('mobilladdare')} /> Mobile charger and mobile holder available</li>
                    <li><input type='checkbox' aria-label='...' {...register('fulltankad')} /> The car must have at least half a tank</li>
                    <li><input type='checkbox' aria-label='...' {...register('adblue')} /> Top up AdBlue and washer fluid if necessary</li>
                </ul>

                <p>Enter a comment if something is missing or if something else should be mentioned: </p>

                <FormControl disabled={isLoading}
                    name='comment'
                    type='text'
                    onChange={(e) => setValue('comment', e.target.value)}
                    componentClass='input'
                    placeholder='add comment...'

                />
            </Modal.Body>

            <Modal.Footer >
                <Button disabled={isLoading} onClick={onClose} > Cancel </Button>
                <Button bsStyle='success' disabled={isLoading}
                    bsSize='large'
                    onClick={submit} > Lock </Button> <br />
                <p> {error} </p>
            </Modal.Footer>
        </Modal>
    )
}

export default LockModal;