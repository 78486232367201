import React from 'react';

import {
    Button,
    Modal
} from 'react-bootstrap';

function TimezynkModal({
    onClose = () => { }
}) {


    return (
        <Modal bsSize='large' show={true} onHide={onClose} >
            <Modal.Header >
                <Modal.Title>Godkänn access till TimeZynk</Modal.Title>
            </Modal.Header >

            <Modal.Body  >
                <p>
                    <b>Grattis!</b>
                    <br />
                    Nu är nästan allt klart för att du ska kunna börja låsa upp och låsa Gordons bilar.<br /><br />
                    Om det är första gången du loggar in, eller om det var länge sen sist, måste du nu ange dina inloggningsuppgifter till <b>TimeZynk</b>. Användarnamn är din <b>e-post adress</b> och lösenordet <b>angav du själv</b> när du loggade in på TImeZynk första gången.
                    <br /><br />
                    Hör av dig till Success Tower om du glömt ditt lösenord eller har andra problem att logga in.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} >Nästa</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TimezynkModal;