

let getToken = () => localStorage.getItem('access_token');


let handleResponse = async (resp) => {

    let json = await resp.json();

    if (json.status !== 'OK')
        throw json.message || json.payload;

    return json.payload || json.data;
}


function getShifts(profileName) {
    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/timezynk?&email=${profileName}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
        }
    }).then((handleResponse))
}


function getPin(regnr, profileName) {
    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/metrotec/getpin/${regnr}/${profileName}`,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken(),
            },
        })
        .then(handleResponse);
}


function unlockVehicleDoor(regnr, profileName, pin, workplace, start) {
    if (!pin || pin < 3)
        return

    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/metrotec/validatepin/${regnr}/${profileName}/${pin}/${workplace}/${start}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
        },
    })
        .then(handleResponse)

}


function lockVehicleDoor(regnr, profileName, workplace, comment = '') {
    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/metrotec/lock/${regnr}/${profileName}/${workplace}`,
        {
            method: 'POST',
            body: JSON.stringify({ comment }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken(),
            },
        })
        .then(handleResponse);
}


export {
    getShifts,
    getPin,
    unlockVehicleDoor,
    lockVehicleDoor
}








