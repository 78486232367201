import React from 'react';

import {
    Button,
    Modal
} from 'react-bootstrap';

function ErrorModal({
    onClose
}) {


    return (
        <Modal bsSize='small' show={true} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Check the checklist!</Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <p>
                    You must go through the checklist before locking the car!
                    <br /><br />
                    Check that the cabinet is locked and that it is neat and tidy.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} > OK </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default ErrorModal;